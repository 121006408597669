<template>
  <div class="merch">
    <!-- 商品更新导入 -->
    <div style="padding: 20px">
      <div class="">
        <div>
          <div class="fx">
            <div class="contense" style="width: 70px">供应商:</div>
            <div class="sch-1 sch-2">
              <el-select
                style="width: 200px"
                clearable
                v-model="upItem.supplierId"
                placeholder="选择供应商"
                filterable
              >
                <el-option
                  v-for="item in businessList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="fx-1" style="padding-top: 20px; width: 500px">
          <UploadDrawer
            ref="updrawer"
            :baseURL="baseURL"
            :pathUrl="'/mgengine/supplierGoods/auditTemplateImport'"
            :Updata="upItem"
            :portType="'app'"
            :deriveUrl="'/mgengine/supplierGoods/goodsTemplate'"
            :deriveInterfaceMode="'get'"
            :deriveName="'代供应商导入商品模版'"
            :TemplaData="TemplaData"
            :showimport="true"
            :confirmed="true"
            @confirming="confirming"
            @closeing="closeing"
            @geterrList="geterrList"
            @downErr="downErr"
          />
        </div>
      </div>
      <div class="xuxian" style="margin-top: 30px"></div>
      <div>
        <div style="margin-bottom: 10px">导表记录</div>
        <!-- 搜索列 -->
        <div class="merch-search">
          <el-form ref="searchData" :model="searchData" :inline="true">
            <el-form-item
              prop="businessName"
              @keyup.enter.native="handleChangeds"
            >
              <el-input
                maxlength="32"
                v-model="searchData.businessName"
                placeholder="请输入供应商名称"
                clearable
                style="width: 200px"
              />
            </el-form-item>
            <el-form-item prop="operatorName">
              <el-input
                maxlength="32"
                v-model="searchData.operatorName"
                placeholder="操作人"
                clearable
                style="width: 200px"
              />
            </el-form-item>
            <el-form-item prop="dateTime">
              <el-date-picker
                v-model="searchData.dateTime"
                type="daterange"
                style="width: 240px"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                unlink-panels
                value-format="yyyy-MM-dd"
                @change="handleChangeds"
              />
            </el-form-item>
            <el-form-item prop="auditStatus">
              <el-select
                v-model="searchData.auditStatus"
                placeholder="审核结果状态"
                clearable
                style="width: 240px"
              >
                <el-option label="待质管审核" value="1" />
                <el-option label="审核已通过" value="2" />
                <el-option label="审核已拒绝" value="3" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleChangeds"
                >查 询</el-button
              >
              <el-button type="primary" size="small" @click="Reset"
                >重 置</el-button
              >
            </el-form-item>
          </el-form>
          <!-- 列表 -->
          <el-table
            :data="behalofList"
            border
            style="width: 100%"
            v-loading="loading"
            height="350px"
          >
            <el-table-column prop="id" label="编号" align="center" />
            <el-table-column
              prop="createTime"
              label="导入时间"
              align="center"
            />
            <el-table-column
              prop="operatorName"
              label="操作人"
              align="center"
            />
            <el-table-column
              prop="businessName"
              label="供应商"
              align="center"
            />
            <el-table-column prop="" label="上传原文件" align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.originTotalNum || "0" }}条</div>
                <div
                  class="btsed"
                  @click="UpImportfile(scope.row.importFileUrl)"
                >
                  下载原文件
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="auditStatus" label="审核状态" align="center">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.auditStatusStr || "—" }}
                </div>
                <div v-if="scope.row.auditStatus == 3" class="grerd">
                  (原因：{{ scope.row.rejectReason }})
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="auditFileUrl"
              label="审核文件"
              align="center"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.auditStatus == 0 || scope.row.auditStatus == 1
                  "
                >
                  —
                </div>
                <div v-if="scope.row.auditStatus == 2">
                  <div
                    v-if="scope.row.auditFileUrl"
                    class="btsed"
                    @click="UpImportfile(scope.row.auditFileUrl)"
                  >
                    下载更新文件
                  </div>
                  <div v-else>无</div>
                </div>
                <div v-if="scope.row.auditStatus == 3">
                  <div
                    v-if="scope.row.auditFileUrl"
                    class="btsed"
                    @click="UpImportfile(scope.row.auditFileUrl)"
                  >
                    下载审核结果文件
                  </div>
                  <div v-else>无</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="导入状态" align="center">
              <template slot-scope="scope">
                <div :style="scope.row.status == 1 ? 'color: #1abca9;' : ''">
                  {{ scope.row.statusStr || "—" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="导入结果" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.status == 2" style="text-align: left">
                  共{{ scope.row.totalNum }}条，成功<span class="green">{{
                    scope.row.successNum
                  }}</span
                  >条
                  <span v-if="scope.row.failNum != 0 && scope.row.failNum != ''"
                    >，失败<span class="redin">{{ scope.row.failNum }}</span
                    >条
                    <span
                      class="btsed"
                      @click="UpImportfile(scope.row.resultFileUrl)"
                      >下载</span
                    >
                  </span>
                </div>
                <div v-else>—</div>
              </template>
            </el-table-column>
            <el-table-column prop="auditor" label="审核人" align="center">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.auditor || "—" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="完成审核时间" align="center">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.auditTime || "—" }}
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="footer">
            <pagination
              v-show="total > 0"
              :total="total"
              :page.sync="searchData.page"
              :limit.sync="searchData.perPage"
              @pagination="getBehalo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
import { downLoadZip } from "@/utils/zipdownload";
import UploadDrawer from "@/components/UploadDrawer/index.vue";
export default {
  name: "",
  components: { UploadDrawer },
  data() {
    return {
      businessList: [],
      baseURL: window.localStorage.getItem("javaBaseUrl"), //导入url
      upItem: {
        supplierId: "", //供应商id
        addCourseForm: 1,
        creatorId: window.sessionStorage.getItem("admin_id"),
        source: 1,
      }, //导入上传数据
      TemplaData: {
        addCourseForm: 1,
      },
      //导入历史数据搜索条件
      searchData: {
        page: 1,
        perPage: 10,
        businessName: "", //供应商名称
        operatorName: "", //操作人
        dateTime: [], //日期筛选
        auditStatus: "", //审核状态
      },
      behalofList: [], //导入历史数据
      total: 0,
      loading: false,
    };
  },
  created() {
    this.init();
  },
  filters: {
    Upstatus(type) {
      switch (type) {
        case 0:
          return "待处理";
        case 1:
          return "处理中";
        case 2:
          return "已完成";
        case 3:
          return "已拦截";
        default:
          return "——";
      }
    },
    UpauditStatus(type) {
      switch (type) {
        case 0:
          return "无需审核";
        case 1:
          return "待质管审核";
        case 2:
          return "审核通过";
        case 3:
          return "审核驳回";
        default:
          return "——";
      }
    },
  },
  methods: {
    ...merchantCenterList.mapActions([
      "postbusinessList", //获取供应商列表数据
    ]),
    ...commonIndex.mapActions([
      "getGoodsImportRecordList", //商品资料库-商品导入记录
      "getPlatformGoodsSupplier", // 商品资料库-获取使用商品资料的供应商
      "deletePlatformGoods", // 商品资料库-删除资料库商品
    ]),
    //初始化
    async init() {
      await this.getbusiness();
      await this.Reset(); //获取代供应商导入历史数据
      Object.assign(this.upItem, this.$options.data().upItem);
    },
    async getbusiness() {
      let res = await this.postbusinessList({
        currPage: 1,
        pageSize: "10000",
      }); //获取供应商数据
      if (res.code == "000000") {
        this.businessList = res.content.list;
      }
    },
    geterrList() {
      this.getBehalo();
    },
    /** 获取代供应商导入历史数据 */
    async getBehalo() {
      this.loading = true;
      var data = { ...this.searchData };
      if (data.dateTime) {
        data.startTime = data.dateTime[0];
        data.endTime = data.dateTime[1];
      } else {
        data.startTime = "";
        data.endTime = "";
      }
      delete data.dateTime;
      let res = await this.getGoodsImportRecordList(data);
      if (res.code == 200) {
        this.behalofList = res.data.data;
        this.total = res.data.total;
      }
      this.loading = false;
    },
    /** 查询 */
    handleChangeds() {
      this.searchData.page = 1;
      this.getBehalo();
    },
    /** 重置 */
    Reset() {
      Object.assign(this.searchData, this.$options.data().searchData);
      this.getBehalo();
    },
    downErr() {
      downLoadZip(
        `/mgengine/supplierGoods/exportError`,
        "xlsx",
        "导入失败数据",
        {},
        "app",
        "get"
      );
    },
    /** 下载导入文件、导入结果 */
    UpImportfile(filerl) {
      if (filerl == "") {
        this.$message.error("未获取下载链接地址");
      } else {
        window.open(filerl);
        this.$message.success("下载成功");
      }
    },
    closeing() {
      Object.assign(this.upItem, this.$options.data().upItem);
    },
    confirming() {
      if (this.upItem.supplierId == "") {
        this.$message.error("请选择供应商");
      } else {
        this.$refs.updrawer.upconfirm();
      }
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-range-separator {
  width: 20% !important;
}
</style>