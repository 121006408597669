<template>
  <div class="upload-wrap">
    <el-form :inline="true">
      <el-form-item label="上传文件" style="position: relative">
        <el-upload
          :action="baseURL + pathUrl"
          :headers="token"
          :show-file-list="true"
          :before-upload="handleBeforeUpload"
          :on-success="
            (response, file) => {
              upSuccess(response, file);
            }
          "
          :limit="1"
          :on-error="
            (error, file) => {
              upError(error, file);
            }
          "
          :data="Updata"
          :on-change="onChange"
          :on-remove="onRemove"
          style="display: inline-block"
          :auto-upload="false"
          ref="upload"
        >
          <el-button>选择excel</el-button>
        </el-upload>
        <div style="position: absolute; top: 0; left: 110px; min-width: 300px">
          <el-button @click="importTemplate" type="text">下载模版</el-button>
        </div>
      </el-form-item>
      <div v-if="showList">
        <span v-for="(v, i) in errList" class="marig_15">
          <download-excel
            :data="v"
            :fields="jsonFields"
            name="导入失败明细.xlsx"
            class="inline"
          >
            <span class="btsing">导入失败记录{{ i + 1 }} </span>
          </download-excel>
        </span>
      </div>
    </el-form>
    <div style="text-align: right">
      <el-button type="primary" @click="confirm">确定上传</el-button>
      <el-button @click="close()">重 置</el-button>
    </div>
    <!--  导入结果提醒-->
    <el-dialog
      title="导入结果提醒"
      :visible.sync="importDialog"
      v-if="importDialog"
      width="380px"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <div>
        共导入
        <span class="green">{{
          Number(shipdata.succeed) + Number(shipdata.error)
        }}</span
        >条，成功 <span class="green">{{ shipdata.succeed }}</span
        >条；导入失败 <span class="green">{{ shipdata.error }}</span
        >条
      </div>
      <div class="text-center mar20">
        <div v-if="shipdata.error != 0">
          <download-excel
            :fetch="fetchData"
            :fields="jsonFields"
            name="导入失败明细.xlsx"
            v-if="this.shipdata.err.length != 0"
          >
            <el-button type="primary" @click="clonseds">
              下载导入失败明细
            </el-button>
          </download-excel>
          <el-button v-else @click="downErr" type="primary">
            下载失败数据
          </el-button>
        </div>
        <el-button @click="clonseds" type="primary" v-else> 知道了 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { downLoadZip } from "@/utils/zipdownload";
export default {
  name: "uploads", //导入文件
  data() {
    return {
      isFile: false, //是否选择商品文件
      token: { token: getToken() },
      uploading: null,
      importDialog: false, //导入结果弹窗
      //导入结果数据
      shipdata: {
        totality: 0, //总数
        //***********java*************************/
        succeed: 0, //成功数
        error: 0, //失败数
        url: "", //导入失败下载地址
        //***********php*************************/
        totalityNum: "", //总数(php客户授权)
        successNum: "", //成功数(php客户授权)
        errNum: "", //失败数(php客户授权)
        err: [], //失败数据(php客户授权)
      },
    };
  },
  props: {
    // 导入地址
    baseURL: {
      type: String,
      default: "",
    },
    // 导入路径
    pathUrl: {
      type: String,
      default: "",
    },
    // 模版下载的地址
    deriveUrl: {
      type: String,
      default: "",
    },
    // 导出表格的名称
    deriveName: {
      type: String,
      default: "",
    },
    // 接口类型（Java/php）
    portType: {
      type: String,
      default: "",
    },
    // 导入接口的入参
    Updata: {
      type: Object,
      default: () => {},
    },
    // 导出接口的请求方式(get/post)
    deriveInterfaceMode: {
      type: String,
      default: "",
    },
    //是否进行导入失败历史记录
    showList: {
      type: Boolean,
      default: false,
    },
    //导入失败历史记录
    errList: {
      type: Array,
      default() {
        return [];
      },
    },
    //是否进行导入结果展示
    showimport: {
      type: Boolean,
      default: false,
    },
    //下载的模版
    jsonFields: {
      type: Object,
      default: () => {},
    },
    //模版入参
    TemplaData: {
      type: Object,
      default: () => {},
    },
    //
    confirmed: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // 本地文件移除
    onRemove(files) {
      this.isFile = false;
      // this.availableSupplierForm.shangcsp = {};
    },
    // 本地添加文件
    onChange(files) {
      this.isFile = true;
      // this.availableSupplierForm.shangcsp = files;
    },
    //导入接口失败
    upError(error) {
      this.uploading.close();
    },
    // 导入接口成功后
    upSuccess(response) {
      if (response.code == "000000" || response.code == 200) {
        this.uploading.close();
        this.$refs.upload.clearFiles();
        if (this.showimport) {
          if (response.content === true) {
            this.$message.success("导入成功");
          } else {
            Object.assign(this.shipdata, this.$options.data().shipdata);
            Object.assign(this.shipdata, response.content);
            this.importDialog = true;
          }
          //** geterrList为导入的历史记录 */
          this.$emit("geterrList");
        } else {
          //** importResult为导入结果后的操作 */
          this.$emit("importResult", response.content, this.title);
        }
      } else {
        this.$refs.upload.clearFiles();
        this.uploading.close();
        this.$message.error({
          showClose: true,
          duration: 0,
          message: response.message,
        });
      }
      this.isFile = false;
    },
    //上传前
    handleBeforeUpload(file) {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const isXls = file.type === "application/vnd.ms-excel";
      //1MB=1024*1024(1MB=1024KB 1KB=1024MB)
      const is10M = file.size / 1024 / 1024 < 10;
      //限制文件上传类型
      if (!isXlsx && !isXls) {
        this.$message.error(
          "导入失败，文件格式不正确，请确认文件格式为.xls或.xlsx。"
        );
        return false;
      }
      //限制文件上传大小
      if (!is10M) {
        this.$message.error("导入失败，文件大小不能超过10MB。");
        return false;
      }
      this.uploading = this.$loading({
        lock: true,
        text: "导入中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      return true;
    },
    // 下载模版/导出
    importTemplate() {
      downLoadZip(
        this.deriveUrl,
        "xlsx",
        this.deriveName,
        this.TemplaData,
        this.portType,
        this.deriveInterfaceMode
      );
    },
    // 导入保存
    confirm() {
      if (!this.isFile) {
        this.$message.error("请上传文件");
      } else {
        if (this.confirmed) {
          this.$emit("confirming");
        } else {
          this.upconfirm();
        }
      }
    },
    upconfirm() {
      this.$refs.upload.submit();
    },
    // 重置
    close() {
      this.$refs.upload.clearFiles();
      this.isFile = false;
      this.$emit("closeing");
    },
    //下载失败数据
    downErr() {
      this.$emit("downErr");
      this.importDialog = false;
      this.importResultDialog = false;
    },
    fetchData() {
      return this.shipdata.err;
    },
    clonseds() {
      this.importDialog = false;
    },
  },
};
</script>

<style lang='scss' scoped>
</style>
